import axios from 'axios';
import {Message} from 'element-ui';
import router from "@/router";
import util from "@/api/util";
import configModel from "@/api/configModel";


export default {

    /**
     * @param path
     * @param api_url
     * @returns {boolean|*}
     */
    domainUrl(path, api_url=false) {
        if( ! path ) {
            path = '/';
        }

        if(path.indexOf("http") === 0) {
            return path;
        }

        if( ! api_url ) {
            api_url = configModel.getConf('api_url', window.location.protocol + '//' + window.location.host);
        }

        if(api_url.lastIndexOf("/") !== api_url.length -1) {
            api_url = api_url + '/';
        }

        if( ! path ) {
            return api_url;
        }

        if(path.indexOf("/") === 0) {
            path = path.substr(1, path.length);
        }

        return api_url + path;
    },

    /**
     * @param path
     * @param image_url
     * @returns {*|boolean}
     */
    domainImageUrl(path, image_url='') {
        return this.domainUrl(path, configModel.getConf('image_url', image_url));
    },

    /**
     * @param data
     * @param defaultValue
     * @returns {any|null|void}
     */
    userModel(data=null, defaultValue=null) {
        var cacheKey = "user-model";
        if(defaultValue === 'login-input')
        {
            if(typeof data === "object") {
                data = JSON.stringify(data);
            }
            return sessionStorage.setItem(cacheKey, data);
        }

        var cacheData = JSON.parse(sessionStorage.getItem(cacheKey));
        if( ! cacheData || ! Object.keys(cacheData).length) {
            return defaultValue;
        }

        if( ! data ) {
            return cacheData;
        }

        if(data.indexOf('.') === -1) {
            data = [data];
        } else {
            data = data.split('.');
        }

        for(var field of data)
        {
            if(typeof cacheData[field] === "undefined") {
                return defaultValue;
            }
            cacheData = cacheData[field];
        }
        return cacheData;
    },

    jumper(path) {
        try{
            router.push(path);
        } catch (e) {}
    },

    /**
     * API 接口网络请求
     * @param url
     * @param options
     */
    request(url, options={}) {
        if(typeof options === "function") {
            var callback = options;
            options = {};
            options.callback = callback;
        }

        var headers = {
            token: util.userModel('token'),
        };
        
        var axiosModel = null;
        var data = options.data || {};
        data.lang = sessionStorage.getItem('language-locale') || 'en';
        url = this.domainUrl(url);
        switch ((options.method || "GET").toUpperCase())
        {
            case 'GET':
                axiosModel = axios.get(url, {params: data, responseType: 'json', headers: headers});
                break;

            case 'POST':
                axiosModel = axios.post(url, data, {responseType: 'json', headers: headers});
                break;

            default:
                axiosModel = axios.request({
                    url: url,
                    method: options.method || 'GET',
                    data: data,
                    headers: headers,
                    responseType: 'json',
                });
                break;

        }

        axiosModel.then(res => this.authResult(res.data, options)).catch(error => {
            if(typeof options.complete === "function") {
                options.complete();
            }
            Message.error(error);
        });
    },

    /**
     * 接口验证
     * @param result
     * @param options
     * @returns {*}
     */
    authResult(result, options={}) {
        try {
            if(typeof options.complete === "function") {
                options.complete(result);
            }

            if(typeof result.code === "undefined") {
                Message.error('网络错误');
                return;
            }

            switch (result.code)
            {
                case 1000:
                case 1001:
                case 1002:
                case 1003:
                case 1004:
                case 1005:
                    Message.error(result.message);
                    if(typeof options.showLogin === "function") {
                        return options.showLogin(result);
                    }
                    break;

                case 1:
                    if(typeof options.error === "function") {
                        options.error(result);
                    }
                    Message.error(result.message);
                    break;

                case 0:
                    if(typeof options.callback === "function") {
                        return options.callback(result);
                    }

                    if(typeof options.success === "function") {
                        options.success(result);
                    }
                    Message.success(result.message);
                    break;

                default:
                    if(typeof options.otherCallback === "function") {
                        return options.otherCallback(result);
                    }
                    Message.error('网络错误');
                    break;
            }
        } catch (error) {
            console.log(error)
        }
    },
}