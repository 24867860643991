import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// lang: this.$root.$i18n.locale,
	},
	getters: {},
	mutations: {
		pullLang(state, payload) {
			console.log(payload)
		}
	},
	actions: {},
	modules: {}
})
