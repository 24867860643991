export default {
    configModel: {},

    setConf(configModel) {
        this.configModel = configModel;
    },

    getConf(fields=null, defaultValue=null) {
        var configModel = JSON.parse(JSON.stringify(this.configModel));
        if(fields === null) {
            return configModel;
        }

        if(fields.indexOf('.') === -1) {
            fields = [fields];
        } else {
            fields = fields.split('.');
        }

        for(var field of fields) {
            if(typeof configModel[field] === "undefined") {
                return defaultValue;
            }
            configModel = configModel[field];
        }
        return configModel;
    },
}