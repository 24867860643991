(function () { //适配设置基础rem
	function w() {
		var r = document.documentElement;
		var a = r.getBoundingClientRect().width;//获取当前设备的宽度
		// if (a < 1280) { //414不固定，根据设计稿的宽度定
		// 	// var rem = a / (a / 10);
		// 	// var rem = a / 41.4;
		// 	a = 1280;
		// }
		r.style.fontSize = a / 130 + "px"
		console.log(document.documentElement.style.fontSize)
	}

	w();
	window.addEventListener("resize", function () { //监听横竖屏切换
		w()
	}, false);
})();