import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	//新增路由
	{path: '/user/balance', component: () => import( '@/views/users/balance.vue')},
	{path: '/ntf/users', component: () => import( '@/views/ntfs/users.vue')},
	{path: '/ntf/detail/:ntf_id', component: () => import( '@/views/ntfs/detail.vue')},
	{path: '/ntfs', component: () => import( '@/views/ntfs/index.vue')},
	{path: '/a9ba7667f8f012e6/detail/:goods_id', component: () => import( '@/views/tudi/detail.vue')},

	//首页
	{
		path: '/',
		name: 'index',
		component: () => import( '../views/home/index.vue'),
		// meta: {
		// 	title: "首页"
		// },
		children: [

			{
				//pc
				path: '/pc',
				name: 'pc',
				component: () => import( '../views/home/pc.vue'),
			},
			{
				//手机
				path: '/mobile',
				name: 'mobile',
				component: () => import( '../views/home/mobile.vue'),
			},
		]
	},
	//土地
	{
		path: '/sdf4sdfsdfa554sdf',
		name: 'tudi',
		component: () => import( '../views/tudi/index.vue'),
		// meta: {
		// 	title: "土地"
		// },
		children: [
			{
				//pc
				path: '/tudi/pc',
				name: 'tudiPc',
				component: () => import( '../views/tudi/pc.vue'),
			},
			{
				//移动
				path: '/tudiPc/mobile',
				name: 'tudiMobile',
				component: () => import( '../views/tudi/mobile.vue'),
			},
		]
	},

	//土地-手机
	{
		path: '/sadfa5sdf',
		name: 'mobileLand',
		// meta: {
		// 	title: "土地"
		// },
		component: () => import( '../views/tudi/mobile-land.vue')
	},
	//头像-pc
	{
		path: '/dx4dx4fweasqweafwe',
		name: 'touxiang',
		component: () => import( '../views/touxiang/index.vue'),
		// meta: {
		// 	title: "头像"
		// },
		children: [
			{
				path: '/touxiang/pc',
				name: 'touxiangPc',
				component: () => import( '../views/touxiang/pc.vue'),
			},
			{
				path: '/touxiang/mobile',
				name: 'touxiangMobile',
				component: () => import( '../views/touxiang/mobile-filter.vue'),
			},
		]
	},
	//手机端-头像-筛选
	{
		path: '/dx4dx4fweasqw/:goods_id',
		component: () => import( '../views/touxiang/detail.vue'),
	},
	{
		path: '/sd4f81as',
		name: 'mobileTouXiangFilter',
		component: () => import( '../views/touxiang/mobile-filter.vue')
	},
	{
		path: '/he',
		name: 'he',
		component: () => import('../components/HelloI18n')
	},
	//内容
	{
		path: '/sd4f54qerqwasds',
		name: 'neiRong',
		// meta: {
		// 	title: "内容"
		// },
		component: () => import( '../components/neiRong.vue')
	},
	{
		path: "/mobilemsdfweasdaf23",
		name: "",
		// meta: {
		// 	title: '内容'
		// },
		component: () => import('../components/mobileNeiRong.vue')
	},
	{
		path: '/s',
		name: 'shequ',
		component: () => import( '../components/mobile-hengxiangGunDong'),
	},
	{
		path: '/sq',
		name: 'shequ',
		component: () => import( '../components/tiger'),
	},
	//社区
	{
		path: '/asd4f8qwesf64sade',
		name: 'shequ',
		component: () => import( '../views/shequ/index.vue'),
		// meta: {
		// 	title: "社区"
		// },
		children: [
			{
				path: '/shequ/zhanKai',
				name: 'shequPc',
				component: () => import( '../views/shequ/pc-zhan-kai.vue'),
			},
			{
				path: '/shequ/mobile',
				name: 'shequMobile',
				component: () => import( '../views/shequ/mobile-zhan-kai.vue'),
			},
		]
	},
	//社区展开-pc
	{
		path: '/f864sade',
		// meta: {
		// 	title: "社区"
		// },
		name: 'zhanKai',
		component: () => import( '../views/shequ/pc.vue')
	},
	//手机端-社区展开
	{
		path: '/sd64f56we',
		name: 'mobileZhanKai',
		// meta: {
		// 	title: "社区"
		// },
		component: () => import( '../views/shequ/mobile-zhan-kai.vue')
	},

	//下载
	{
		path: '/asd4fqwradsad8w',
		name: 'download',
		// meta: {
		// 	title: "下载"
		// },
		component: () => import( '../views/download/index.vue'),
		children: [
			{
				//pc
				path: '/download/pc',
				name: 'downloadPC',
				component: () => import( '../views/download/pc.vue'),
			},
			{
				//移动
				path: '/download/mobile',
				name: 'downloadMobile',
				component: () => import( '../views/download/mobile.vue'),
			},
		]
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, saveTop) {
		if (saveTop) {
			return saveTop;
		} else {
			return {x: 0, y: 0}
		}
	},
})
router.beforeEach((to, from, next) => {
	// /* 路由发生变化修改页面title */
	// if (to.meta.title) {
	// 	document.title = to.meta.title;
	// }
	next()
});
export default router
