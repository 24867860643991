import router from '@/router/index';

export default {
    /**
     * @param params
     * @param field
     * @param def
     * @param is_empty
     * @returns {string|*}
     */
    isset(params, field, def='', is_empty=false){
        try{
            if(typeof params[field] === "undefined") {
                return def;
            }

            if(is_empty && ! params[field]) {
                return def;
            }
            return params[field];
        }catch (e) {
            return def;
        }
    },

    checkLogin() {
        if( ! this.userModel('user_id', false) ) {
            return false;
        }
        return true;
    },

    /**
     * @param data
     * @param defaultValue
     * @returns {any|null|void}
     */
    userModel(data=null, defaultValue=null) {
        var cacheKey = "user-model";
        if(defaultValue === 'login-input')
        {
            if(typeof data === "object") {
                data = JSON.stringify(data);
            }
            return sessionStorage.setItem(cacheKey, data);
        }

        var cacheData = JSON.parse(sessionStorage.getItem(cacheKey));
        if( ! cacheData || ! Object.keys(cacheData).length) {
            return defaultValue;
        }

        if( ! data ) {
            return cacheData;
        }

        if(data.indexOf('.') === -1) {
            data = [data];
        } else {
            data = data.split('.');
        }

        for(var field of data)
        {
            if(typeof cacheData[field] === "undefined") {
                return defaultValue;
            }
            cacheData = cacheData[field];
        }
        return cacheData;
    },

    jumper(path) {
        router.push(path);
    },
}