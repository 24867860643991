<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
  mounted() {
    window.onresize = () => {



      console.log(document.body.clientWidth)
    }
  }
}
</script>

<style lang="less">
#app{
  flex: 1;
  width: auto;
  height: auto;
}
</style>
