import Vue from 'vue'
import App from './App.vue'

import util from './api/util'
import Http from "@/api/http";
import router from './router'
import store from './store'

import "./assets/global.css"
import 'amfe-flexible';
import "./assets/rem"

import Vant from 'vant';
import 'vant/lib/index.css';

import * as echarts from "echarts";
Vue.prototype.$util = util;
Vue.prototype.$http = Http;
Vue.prototype.$echarts = echarts
Vue.use(Vant)

Vue.config.productionTip = false
import ElementUI from 'element-ui';
import locale from '../node_modules/element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from './i18n'
import http from "@/api/http";
Vue.use(ElementUI,{locale});

import configModel from '@/api/configModel';
http.request('https://nft.tatagame.cn/api/configs', res => {
  configModel.setConf(res.data || {});
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})